<template>
  <div class="about">
    <Header />
    <div>
    <h2>Edit Domain</h2>
    <form @submit.prevent="update">
        <div>
            <!-- <label>Domain Name</label>
            <input v-model="form.name" required /> -->
            <InputText class="p-mb-2 p-mt-2" type="text" placeholder="Insert Domain Name" v-model="form.name" required />
        </div>
        <!-- <button type="submit">Update Domain</button> -->
        <Button label="Update Domain" type="submit" />
    </form>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import {reactive, computed, onMounted} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {getDomain, updateDomain} from '@/fbDomain'
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

export default {
  components: {Header, InputText, Button},
  setup() {
    const router = useRouter()
    const route = useRoute()
    const domainId = computed(() => route.params.id)

    const form = reactive({name: ''})
    onMounted(async () => {
        const domain = await getDomain(domainId.value)
        form.name = domain.name
    })

    const update = async () => {
        await updateDomain(domainId.value, { ...form})
        router.push('/domains')
        form.name =''
    }

    return {form, update}
  },
}
</script>

